import { makeStyles } from '@material-ui/core/styles';

const style = (theme) => ({
  content: {
    flexGrow: 1,
    padding: theme.spacing(0, 2),
  },
  cardSection: {
    padding: theme.spacing(2, 0, 0, 0),
    textAlign: 'center',
    '& + &': {
      borderTop: `2px solid ${theme.palette.background.default}`,
    },
  },
  cardTitle: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'center',
    [theme.breakpoints.up('sm')]: {
      minHeight: 80,
    },
  },
  titleHighlight: {
    color: theme.palette.services.broadband,
  },
  detailsDropdownRoot: {
    marginTop: theme.spacing(2),
  },
  detailsDropdownTrigger: {
    borderBottom: 'none',
  },
  detailsDropdown: {
    padding: theme.spacing(1, 0),
  },
  promoLabel: {
    marginBottom: -1,
    float: 'right',
  },
  promoStyles: {
    color: theme.palette.success.main,
  },
  monthlyAndTotalPrice: {
    marginBottom: theme.spacing(1),
  },
});

export default makeStyles(style);
