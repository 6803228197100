import makeStyles from '@material-ui/core/styles/makeStyles';

const styles = (theme) => ({
  heading: {
    margin: theme.spacing(2, 0),
  },
  bodyRoot: {
    marginBottom: theme.spacing(4),
  },
  productSelectionError: {
    display: 'inline-flex',
    alignItems: 'center',
  },
  alertBanner: {
    marginTop: theme.spacing(3),
  },
  promoBanner: {
    margin: theme.spacing(2, 0),
    maxWidth: 1030,
  },
  freeEnergyBanner: {
    maxWidth: 680,
    marginTop: theme.spacing(3),
  },
});

export default makeStyles(styles);
